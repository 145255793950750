import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>감사합니다!</h1>
          <p>빠른 시일내에 연락드리겠습니다.</p>
        </div>
      </div>
    </section>
  </Layout>
)
